<script type="text/javascript"></script>

<template>
  <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center">
   <div class = "container" >

    <div id="signuppage"> 
      <p class="logo"><a href ="https://flowe.io" ><img src="@/static/img/flowe.png" class="imglogo"></a></p>
      <div class="pagetitle">
        <h2 class = "text-center">Your account has been deleted.</h2>
        <p class = "text-center">Thank you for using Flowe. If you change your mind, you can always <a class="textprimary" href="/sign-up">sign up again</a>.</p> 
      </div>


        </div>
      </div>
    </div>
  </template>	
  <style type="text/css" scoped>

  :root{
    /* --G700: #157815; */
    --N900: #313338;
  }

  #app{
    background-color: #fff;
  }

  html,
  body {
    color: var(--N900);
    height: 100%;
  }

  body {
    padding-top: 10%;
    min-width: 360px;
  }


  h1, h2{ text-align: center; }

  .pagetitle{
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
    margin-bottom: 1rem;
    margin-top: 3%;
  }


  .form_wrapper{
    margin: 0 auto;
    max-width: 350px;
  }


  #signuppage2 .form_wrapper{ max-width: 400px; }

  #responsepage .signuppage .pagetitle{
    max-width: 500px;
  }

  .btn-google img{ 
    width: 24px;
    height: 24px;
    margin-right:.3em; 
  }

  p.logo .imglogo{ 
    width: 200px; 
  }
  p.logo{
    text-align: center;
    width: 100%;
    margin: auto;
  }


</style>
<script type="text/javascript"></script>
<script>

  import Userapi from '@/services/api/users'

  import {TopBar} from '@/components/topbar'

  export default{
    data(){
      return{
          userid : null,
          users : [],
          pick :"",
          status : 'working'
        }
        },

        computed: {},
        filters: {},
        mounted() {

          $('#preloader').addClass('hide')

          this.confirmdeactivate()

        },
        computed : {

          token(){
            return localStorage.token
          }

        },
        methods: {

          confirmdeactivate(){


            let self = this

            var confirmtoken = this.$route.query.confirmtoken

            Userapi.Deactivate( this.token, confirmtoken )
            .then( function(response){


           // if( response.data.ok ){



              self.$cookie.set('usertoken', '');
              self.$cookie.set('organizationkey', '');
              self.$cookie.set('signinmethod', "");

              localStorage.token = ""

              self.$cookie.delete('usertoken');
              self.$cookie.delete('organizationkey');
              self.$cookie.delete('signinmethod');

              self.$cookie.delete('showorghelp');
              
              //auth.logout();


              
              location.href = "/sign-out"

              //self.status = 'done'
              //alert('Account has been removed successfully')
              //self.$cookie.set('usertoken','')
             // self.$store.dispatch('doClearsession')

            //}else{

            //}


            })
            .catch(error => console.log(error))
            .finally( () => {
            })


          }
        },components:{
    TopBar
  }
      }
</script>